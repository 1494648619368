import React from 'react';
import {ProgressBar} from 'react-bootstrap';

const Loading = ({progress}) => {

  return (
    <div>
      <ProgressBar variant="info" striped now={progress} />
    </div>
  )
}

export default Loading
