import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';

const ChatImage = (props) => {
    let image = props.image;
    //console.log('image')
    //console.log(image);
    
    if (image == null || image == "") {
        return null;
    }

    let width = props?.width ? props.width : null;
    let height = props?.height ? props.height : null;
    let size = props?.size ? props.size : null;
    let dimensions = {}

    switch (size) {
        case 'sm':
            //console.log('small size')
            dimensions = { width: 100, height: 100 };
            break;
        case 'md':
            dimensions = { width: 256, height: 256 };
            break;
        case 'lg':
            dimensions = { width: 512, height: 512 };
            break;
        default:
            if (width != null) {
                dimensions.width = width;
            }
            if (height != null) {
                dimensions.height = height;
            }
            if(width == null && height == null){
                //fill 
                //dimensions.class = 'image-fluid'
            }
            break;
    }

    return (
        <>
            <div className={'d-flex justify-content-center'}><Image src={image} {...dimensions} className='img-fluid chatImage' /></div>
            {/*<Image src={image} {...dimensions} className='img-fluid'/>*/}
        </>
    )
}
export default ChatImage;