import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {apiUrl} from '../../util/Api';

export const loadUser = createAsyncThunk('users/loadUser', async() => {
  //console.log('UserSlice.loadUser: ' + (new Date()).toLocaleTimeString())
  //don't need to do refresh to reload unless we are pulling in additional user info
  ///auth
  axios.post(apiUrl + '/login/refresh', {}, {withCredentials: true})
  .then((res) => {
    if(res.data.status === "ok" && res.data.user && res.data.user.isActive === 1) {
      //login
      //saveUserStore(res.data.user);
      //console.log('user returned')
      return res.data.user
    } else {
      //clearUserStore()
      //console.log('loadUser: no valid user')
      return null
    }
  })
  .catch((err) => {
    //console.log('loadUser error')
    return null
  });
})

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    authenticated: false,
    user: null,
    status: null,
    error: null
  },
  reducers: {
    saveUserStore: (state, action) => {
      //console.log('saveUserStore.action', action)
      //state.push(action.payload)
      if(action.payload !== null && action.payload.userKey){
        //console.log('saveUserStore logged in')
        state.authenticated = true;
        state.user = action.payload;
      } else {
        //console.log('saveUserStore not logged in')
        state.authenticated = false;
        state.user = null;
      }
    },
    clearUserStore: state => {
      //console.log('clear user')
      state.authenticated = false;
      state.user = null;
    }
  },
  extraReducers: {
    [loadUser.pending]: (state, action) => {
      state.authenticated = false;
      state.user = null;
      state.status = 'loading'
    },
    [loadUser.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.authenticated = true;
      state.user = action.payload;
    },
    [loadUser.rejected]: (state, action) => {
      state.status = 'failed'
      state.authenticated = false;
      state.user = null;
    }
  }
});

export const {saveUserStore, clearUserStore} = UserSlice.actions;
//export const getUserStore = state => state.user;
export const getUserStore = state => state.user ? state.user.user : null;
export const isUserAuthenticated = state => state.user.authenticated ;
export const getUserLoadingStatus = state => state.user.status;
export const getDefaultAccountKey = state => state.user.user ? state.user.user.defaultAccountKey : null;
export const getUserAccounts = state => state.user.user ? state.user.user.accounts : null;
export const getUserAccountLimit = state => state.user?.user?.accountLimit ? state.user.user.accountLimit : null;
export const getUserKey = state => state.user ? state.user.user.userKey : null; 
export default UserSlice.reducer;
