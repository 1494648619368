import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
//Redirect
import axios from 'axios';
import { apiUrl } from '../../util/Api';
import ChatImage from './ChatImage';
import ChatThread from './ChatThread';
//import {string, object} from 'yup';
import * as Yup from 'yup';
const MAX_INPUT_LENGTH = parseInt(process.env.REACT_APP_MAX_INPUT_LENGTH || 300)

const validationSchema = Yup.object({
    //name: Yup.string().required('please enter your name'),
    //email: Yup.string().email('please enter a valid email').required('email is required'),
    //subject: Yup.string().required('please enter a subject'),
    message: Yup.string().required('please enter a message').max(MAX_INPUT_LENGTH,'The message exceeds the limit'),
});

const Chat = () => {
    const [errors, setErrors] = useState([]);
    const [messages, setMessages] = useState([])
    const [catImage, setCatImage] = useState('/cat-1024-2.png')
    const [typing, setTyping] = useState(false)

    const [includeImageToggle, setIncludeImageToggle] = useState(true);
    const [initialFormValues, setInitialFormValues] = useState({ message: '', includeImage: true })

    const [welcomeMessage, setWelcomeMessage] = useState(null)

    //const [successMsgs, setSuccessMsgs] = useState([])
    const [showForm, setShowForm] = useState(true);
    //const [referrer, setReferrer] = useState(null)

    useEffect(() => {
        document.title = `Ask the Cat`;
        
        //get a welcome message 
        if(welcomeMessage == null) {
            setTyping(true)
            let options = {
                message: "welcome me to chat",
                includeImage: false
            }
            axios.post(apiUrl + '/chat/chat', options, { withCredentials: true })
            .then((res) => {
                setTyping(false)
                if (res.data.status === "ok") {
                    if (res.data.image != null && res.data.image != "") {
                        setCatImage(res.data.image)
                    }
                    setWelcomeMessage(res.data.text)
                    //update text 
                    setMessages(messages => [...messages, { time: Date.now(), role: "assistant", type: "text", text: res.data.text }])
                    //setShowForm(false)
                    
                }
                else {
                    let errorMsgs = [];
                    res.data.errors.forEach((error, i) => {
                        switch (error) {
                            case 'message-missing':
                                errorMsgs.push('Please enter a message.');
                                break;
                            default:
                                errorMsgs.push('The kitty is currently napping');
                        }
                    });
                    setErrors(errorMsgs);
                }
            })
            .catch((err) => {
                setTyping(false)
                setErrors(['The kitty is currently napping']);
            });
        }
    }, [])

    const submitForm = (values, { resetForm }) => {
        setErrors([]);
        //update text 
        //console.log(values);
        setMessages(messages => [...messages, { time: Date.now(), role: "user", type: "text", text: values.message }])

        let options = {
            message: values?.message
        }
        //update image?
        if (values.hasOwnProperty('includeImage') && values.includeImage == true) {
            options.includeImage = true;
        }
        else {
            options.includeImage = false;
        }

        //clear values from form 
        //{message:'',includeImage:options.includeImage}
        resetForm({ values: { message: '', includeImage: options.includeImage } })
        //setIncludeImageToggle(options.includeImage)
        //setInitialFormValues({message:'', includeImage:options.includeImage});

        setTyping(true)

        axios.post(apiUrl + '/chat/chat', options, { withCredentials: true })
            .then((res) => {
                setTyping(false)
                if (res.data.status === "ok") {

                    //console.log(JSON.stringify(res.data))
                    //if image, update image 
                    if (res.data.image != null && res.data.image != "") {
                        setCatImage(res.data.image)
                    }
                    //update text 
                    setMessages(messages => [...messages, { time: Date.now(), role: "assistant", type: "text", text: res.data.text }])
                    //setShowForm(false)
                }
                else {

                    let errorMsgs = [];
                    res.data.errors.forEach((error, i) => {
                        switch (error) {
                            case 'message-missing':
                                errorMsgs.push('Please enter a message.');
                                break;
                            default:
                                errorMsgs.push('There was an error processing this request.');
                        }
                    });
                    setErrors(errorMsgs);
                }
            })
            .catch((err) => {
                setTyping(false)
                setErrors(['An error occured. Try again later.']);
            });
    }

    //added mx-auto to center
    return (
        <>
            <Container fluid >
                <Row>
                    <Col sm={9} md={8} lg={7} xl={6} className="chatApp mx-auto">
                        <Row>
                            <Col style={{ backgroundColor: "#ffffff" }}>
                                <ChatImage image={catImage} size={'lgx'} />
                            </Col>

                        </Row>
                        <Row className={'thread-area-all overflow-scroll mt-2'} style={{ backgroundColor: "#ffffff" }}>
                            <ChatThread thread={messages} />
                        </Row>

                        <Row>

                            <Col>
                                <Row>
                                    <div style={{ backgroundColor: "#ffffff" }}>
                                        {
                                            errors.length > 0 ? (
                                                <Alert variant='danger'>
                                                    {errors.map((err, i) => (<div key={'err.' + i}>{err}</div>))}
                                                </Alert>
                                            ) : (
                                                <div className={`chat-bubble chat-out:chat-dir mb-1 ${typing ? '' : 'invisible'}`}>
                                                    <div className="chat-typing">
                                                        <span className="ct-circle scaling"></span>
                                                        <span className="ct-circle scaling"></span>
                                                        <span className="ct-circle scaling"></span>
                                                    </div>
                                                </div>
                                            )
                                        }


                                    </div>
                                </Row>
                                <Row>


                                    {showForm ? (
                                        <>
                                            <Formik validationSchema={validationSchema}
                                                onSubmit={submitForm}
                                                initialValues={{ message: '', includeImage: true }}
                                            >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    touched,
                                                    isValid,
                                                    errors
                                                }) => (
                                                    <Form noValidate onSubmit={handleSubmit} className='my-2'>
                                                        <Row>
                                                            <Col >
                                                                <Form.Group controlId='chat-message' >
                                                                    {/*<Form.Control as="textarea" rows={2} placeholder=""
                          name="message" value={values.message} onChange={handleChange}
                          isInvalid={touched.message && errors.message}
                        />*/}
                                                                    <Form.Control type="text" placeholder=""
                                                                        name="message" value={values.message} onChange={handleChange}
                                                                        isInvalid={touched.message && errors.message}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs="auto">
                                                                <Button variant="primary" type="submit">Send</Button>
                                                            </Col>{/* */}
                                                        </Row>
                                                        <Row>
                                                            <Col >
                                                                <Form.Check type="switch" name="includeImage" id="includeImage" label="Update image after each message (slower)" className='mt-2' onChange={handleChange} value={true} defaultChecked={includeImageToggle} />
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </>
                                    ) : (null)}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>

    );
}


export default Chat;
