import React from 'react';
import {Container} from 'react-bootstrap';

const Footer = () => {

  const currYear = (new Date()).getFullYear();

  return (
      <Container fluid id="footer" className='mt-5 py-3'>
      <div className='text-center text-secondary'>
      &copy;Copyright <a href="https://www.whobeta.com">WhoBeta</a> {currYear}
      </div>
      </Container>
  )
}

export default Footer;
