import React from 'react';
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import PrivateRoute from './util/PrivateRoute';
import PublicRoute from './util/PublicRoute';

import Chat from './routes/chat/Chat';
import ChatMain from './routes/chat/ChatMain';
import DogChat from './routes/chat/DogChat';
import Home from './routes/Home';
import NotFound from './components/NotFound';


function App() {
  return (
    <>
    <Routes>
      {/*<Route path="/" element={<PublicRoute><ChatMain/></PublicRoute>} />*/}
      <Route path="/" element={<Home/>}/>
      <Route path="/chat" element={<Chat/>}/>
      <Route path="/dogmode" element={<DogChat/>}/>
      <Route path="*" element={<PublicRoute><NotFound/></PublicRoute>}/>
    </Routes>
    </>
  )
}

/*
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header>
    </div>
  );
}
*/
export default App;
