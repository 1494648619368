import React, {useState, useEffect} from 'react';
import {Row} from 'react-bootstrap';
import ChatInteraction from './ChatInteraction';

const ChatThread = (props) => {
    let thread = props?.thread;

    //console.log('tread')
    //console.log(thread)

    if (thread === null || thread.length <= 0) {
        return null;
    }

    return (
        <div>
            {thread.length > 0 ? (
                <>
                {thread.map((message, i) => (
                    <ChatInteraction key={`interaction.${i}`} interaction={message}/>
                ))}
                </>
            ) : (null)}
        </div>
    )
}
export default ChatThread;

