import React from 'react';
import { Image, Row } from 'react-bootstrap';
import { toShortDateTimeDisplayFromStr } from '../../util/Util';

const ChatInteraction = (props) => {
    let interaction = props.interaction;
    if(interaction === null) {
        return null;
    }

    //console.log('interaction')
    //console.log(interaction)

    let text = interaction?.text ? interaction.text : null;
    let type = interaction?.type ? interaction.type : null;
    let image = interaction?.image ? interaction.image : null;
    //let dir = interaction?.dir ? interaction.dir : null;
    let role = interaction?.role ? interaction.role : null;



    return (
        <div className={`d-flex justify-content-${role === "user" ? 'end' : 'start'} transcript-msg`}>
            <div className={`chat-bubble chat-${role}`}>
                {text}
            </div>
        </div>
    )

    /*
    switch (type) {
        case 'typing': 
            return
            (
                <Row>
                    <div className={`chat-bubble chat-${dir}`}>
                        ...
                    </div>
                </Row>
            )
            break;
           
        case "image":
            //if an image - optional 
            let width = props?.width ? props.width : null;
            let height = props?.height ? props.height : null;
            let size = props?.size ? props.size : null;
            let dimensions = {}
            switch (size) {
                case 'sm':
                    dimensions = { width: 100, heigh: 100 };
                    break;
                case 'md':
                    dimensions = { width: 256, heigh: 256 };
                    break;
                case 'lg':
                    dimensions = { width: 512, heigh: 512 };
                    break;
                default:
                    if (width != null) {
                        dimensions.width = width;
                    }
                    if (height != null) {
                        dimensions.height = height;
                    }
                    break;
            }
            return (
                <Row>
                    <div className={`chat-bubble chat-${dir}`}>
                        <Image src={image} {...dimensions} />
                    </div>
                </Row>

            )
            break;
        case "text":
        default:
            return
            (
                <Row>
                    <div className={`chat-bubble chat-${dir}`}>
                        {text}
                    </div>
                </Row>
            )
            break;
    }*/
}
export default ChatInteraction;