import React from 'react';
//import {Route} from 'react-router-dom';
import {Container, Row} from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
const PublicRoute = ({children}) => {
  //col-md-10 ms-sm-auto col-lg-10 px-md-4 pt-md-4 mainApp
  //ms-sm-auto seems push the left margin out for a left nav
  //"margin start" - "small" - "auto"
  //so as long as it's small or bigger, there's the left nav space - I think
  //mx-auto centers instead 
  return (
    <>
      <Header/>
      <Container fluid >
        <Row >
        <main className="col-md-10 mx-auto col-lg-10 px-md-4 pt-md-4 mainApp">
            {children}
        </main>
        </Row>
        
      </Container>
      <Footer/>
    </>
  )
};
/*
const PublicRoute = ({component: Component, ...rest}) => {
  //console.log('in public route');

  return (
    <Route {...rest}>
      <>
        <Header/>
        <Container fluid className="public-component-parent">
          <Row className="justify-content-center">
            <div className="public-component">
              <Component/>
            </div>
          </Row>
        </Container>
      </>
    </Route>
  );

};
*/
export default PublicRoute;
