import React from 'react';
import {Alert, Col} from 'react-bootstrap';
const NotFound = (props) => {
  //console.log('NotFound')
  return (
    <Col xs={12} sm={9} md={9} lg={9} className="public-component mx-auto">
      <Alert variant='danger'>
      The page could not be found.
      </Alert>
    </Col>
  )
}

export default NotFound
