import React from 'react';
import {Image, Col, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Footer from '../components/Footer'

const Home = (props) => {
  //console.log('NotFound')
  //sm={8} md={8} lg={6} xl={5}
  //xs={12} sm={9} md={9} lg={9}
  return (
    <>
    <Row>
    <Col sm={8} md={8} lg={6} xl={5} className="public-component mx-auto">
      <Image src="/cat-1024-2.png" className='img-fluid'/>
    </Col>
    </Row>
    <Row>
      <Col className='text-center'>
      <Link to={'/chat'} className='btn btn-primary mt-4'>Start Chatting</Link>
      </Col>
    
    </Row>
    <Row>
    <Col className='text-center mt-3'>
      <Link to={'/dogmode'} className='mt-4'>Dog Mode</Link>
      </Col>
    </Row>
    <Footer/>
    </>
  )
}

export default Home
