import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
//Redirect
import axios from 'axios';
import { apiUrl } from '../../util/Api';
import ChatImage from './ChatImage';
import ChatThread from './ChatThread';

//import {string, object} from 'yup';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  //name: Yup.string().required('please enter your name'),
  //email: Yup.string().email('please enter a valid email').required('email is required'),
  //subject: Yup.string().required('please enter a subject'),
  message: Yup.string().required('please enter a message'),
});

const ChatMain = () => {
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([])
  const [catImage, setCatImage] = useState('/cat-1024.png')
  const [typing, setTyping] = useState(false)

  const [includeImageToggle, setIncludeImageToggle] = useState(true);
  const [initialFormValues, setInitialFormValues] = useState({ message: '', includeImage:true})

  //const [successMsgs, setSuccessMsgs] = useState([])
  const [showForm, setShowForm] = useState(true);
  //const [referrer, setReferrer] = useState(null)

  useEffect(() => {
    document.title = `Ask the Cat`;
  }, [])

  const submitForm = (values, {resetForm}) => {
    setErrors([]);
    //update text 
    //console.log(values);
    setMessages(messages => [...messages, {time: Date.now(), role:"user", type:"text", text:values.message}])

    let options = {
      message: values?.message
    }
    //update image?
    if(values.hasOwnProperty('includeImage') && values.includeImage == true) {
      options.includeImage = true;
    }
    else {
      options.includeImage = false;
    }

    //clear values from form 
    //{message:'',includeImage:options.includeImage}
    resetForm({values: {message: '', includeImage:options.includeImage}})
    //setIncludeImageToggle(options.includeImage)
    //setInitialFormValues({message:'', includeImage:options.includeImage});

    setTyping(true)

    axios.post(apiUrl + '/chat/chat', options, { withCredentials: true })
      .then((res) => {
        setTyping(false)
        if (res.data.status === "ok") {
          
          //console.log(JSON.stringify(res.data))
          //if image, update image 
          if(res.data.image != null && res.data.image != ""){
            setCatImage(res.data.image)
          }
          //update text 
          setMessages(messages => [...messages, {time: Date.now(), role:"assistant", type:"text", text:res.data.text}])
          //setShowForm(false)
        }
        else {

          let errorMsgs = [];
          res.data.errors.forEach((error, i) => {
            switch (error) {
              case 'message-missing':
                errorMsgs.push('Please enter a message.');
                break;
              default:
                errorMsgs.push('There was an error processing this request.');
            }
          });
          setErrors(errorMsgs);
        }
      })
      .catch((err) => {
        setTyping(false)
        setErrors(['An error occured. Try again later.']);
      });
  }

  //added mx-auto to center
  return (
    <>
      <Col xs={12} sm={9} md={9} lg={9} className="public-component mx-auto">
        <Row>
          <Col>
          <ChatImage image={catImage} size={'lgx'} />
          </Col>
          
        </Row>
        <Row className={'thread-area overflow-scroll mt-2'}>
          <ChatThread thread={messages}/>
        </Row>
        <Row>
            <div>
              <div className={`chat-bubble chat-out:chat-dir mb-1 ${typing ? '' : 'invisible'}`}>
                <div className="chat-typing">
                <span className="ct-circle scaling"></span>
                <span className="ct-circle scaling"></span>
                <span className="ct-circle scaling"></span>
              </div>
              </div>
              
            </div>
        </Row>
        <Row >
          <div >
            {
              errors.length > 0 ? (
                <Alert variant='danger'>
                  {errors.map((err, i) => (<div key={'err.' + i}>{err}</div>))}
                </Alert>
              ) : (null)
            }
            

            {showForm ? (
              <>
                <Formik validationSchema={validationSchema}
                  onSubmit={submitForm}
                  initialValues={{ message: '', includeImage:true}}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} className='my-2'>
                      <Row>
                      <Col >
                      <Form.Group controlId='chat-message' >
                        {/*<Form.Control as="textarea" rows={2} placeholder=""
                          name="message" value={values.message} onChange={handleChange}
                          isInvalid={touched.message && errors.message}
                        />*/}
                        <Form.Control type="text" placeholder=""
                          name="message" value={values.message} onChange={handleChange}
                          isInvalid={touched.message && errors.message}
                        />
                        <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                      </Form.Group>
                      </Col>
                      <Col xs="auto">
                      <Button variant="primary" type="submit">Send</Button>
                      </Col>{/* */}
                      </Row>
                      <Row>
                      <Col >
                          <Form.Check type="switch" name="includeImage" id="includeImage" label="Update image after each message (slower)" className='mt-2' onChange={handleChange} value={true} defaultChecked={includeImageToggle}/>
                          </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (null)}

          </div>
        </Row>
      </Col>
    </>

  );
}


export default ChatMain;
