import React from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {getUserStore, getDefaultAccountKey, getUserAccounts} from '../app/slices/UserSlice';

const Header = (props) => {

  const user = useSelector(getUserStore);
  const isAuth = user ? true: false;

  //TODO: NOTE: may need to add + '/' after account key in drop down so relative urls in subsequent pages work

  const defaultAccountKey = useSelector(getDefaultAccountKey);
  let accountKey = props.accountKey ? props.accountKey : defaultAccountKey;
  const accounts = useSelector(getUserAccounts);
  let currentAccountName = 'My Account';
  if(Array.isArray(accounts)) {
    let currentAccount = accounts.find((acc) => acc.accountKey === accountKey);
    //if no currentAccount - should redirect to login ?
    if(currentAccount) {
      currentAccountName = currentAccount.account;
    }
    //else {
      //redirect ?
    //}
  }

  const marketingHome = process.env.REACT_APP_WEB_URL || "/";

  //changed header logo to one with name, added the navbar-brand-theme to reduce header height better
  //remove the css and put height and width back to 25 if switching back

  return (
    <Navbar id="header" fixed="top" sticky="top" expand="sm" className="px-3 border-bottom ">

      <Navbar.Brand href={marketingHome} className='navbar-brand-theme' ><img src="/askthecat.png" width="137" height="40" alt="ask the cat"/></Navbar.Brand>
      
      {/*
      <Navbar.Toggle aria-controls="header-nav"/>
      <Navbar.Collapse id="header-nav">

       
      {
        isAuth ?
        <>
          <Nav className="ms-auto">

            <NavDropdown title={currentAccountName} className="header-dropdown">
              {
                Array.isArray(accounts) ? (
                  <>
                  {accounts.map((account, i) => (<NavDropdown.Item key={'account.' + i} href={'/account/' + account.accountKey}>{account.account}</NavDropdown.Item>))}
                  </>
                ) : (null)
              }
              <NavDropdown.Item href={'/account/' + accountKey + '/account'}>Account</NavDropdown.Item>
              <NavDropdown.Item href={'/account/' + accountKey + '/profile'}>Profile</NavDropdown.Item>
              <NavDropdown.Item href={'/account/' + accountKey + '/support'}>Support</NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link href="/logout">Logout</Nav.Link>
            </Nav.Item>
          </Nav>
        </>
        :
        <>

          <Nav className="ms-auto">
          <Nav.Link href="/signup">Signup</Nav.Link>
          <Nav.Link href="/login">Login</Nav.Link>
          </Nav>
        </>
      }
      

      </Navbar.Collapse>
        */}
    </Navbar>
  );
}

export default Header;
